import helpers from "./helpers";
import datetimepicker from "./datetimepicker";
import toast from "./toast";
import datepicker from "./datepicker";
import { isNull, isUndefined } from "lodash";
import { formatDateTime } from "./date-formatters";
import dateRangePicker from "./date-range-picker";
import { addYears } from "date-fns";
import {
  isSelectComponent,
  setSelectSelectedOption,
  unselectSelectedOptions
} from "./single-components/selectComponent";
import { dispatchManualEvent } from "../helpers/htmlUtils";
import CountrySelect from "./single-components/CountrySelect";
import { jobStatusTypeId } from "./enums";

const $ = (el) => document.querySelectorAll(el);

export const dragDrop = ({ dragzone, parent = document.body }) => {
  const preventAction = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  let dragRemoveTimer;

  dragzone.addEventListener("dragleave", () => {
    dragRemoveTimer = setTimeout(() => {
      parent.classList.remove("dragging-file");
    }, 100);
  });

  dragzone.addEventListener("dragend", () => {
    parent.classList.remove("dragging-file");
  });

  dragzone.addEventListener("dragenter", () => {
    clearTimeout(dragRemoveTimer);
    parent.classList.add("dragging-file");
  });

  dragzone.addEventListener("dragover", () => {
    clearTimeout(dragRemoveTimer);
    parent.classList.add("dragging-file");
  });

  dragzone.addEventListener("drop", () => {
    parent.classList.remove("dragging-file");
  });

  parent.addEventListener("drag", (e) => preventAction(e));
  parent.addEventListener("dragstart", (e) => preventAction(e));
  parent.addEventListener("dragend", (e) => preventAction(e));
  parent.addEventListener("dragover", (e) => preventAction(e));
  parent.addEventListener("dragenter", (e) => preventAction(e));
  parent.addEventListener("dragleave", (e) => preventAction(e));
  parent.addEventListener("drop", (e) => preventAction(e));
};

export const createGridDropdown = (arr, buttonText) => `
   <div class='dropdown grid__dropdown'>
      <button
         class="btn dropdown-toggle"
         type="button"
         data-toggle="dropdown"
         aria-haspopup="true"
         aria-expanded="false"
      >
         ${
           helpers.stripHTML(buttonText) ||
           '<i class="fal fa-ellipsis-h-alt"></i>'
         }
      </button>
      <div
         class="dropdown-menu no-bullet grid__dropdown-menu js-dropdown-menu"
         aria-labelledby="dropdownMenuButton"
      >
         ${arr
           .map(
             ({ text, cls, data }) =>
               `<a ${
                 data !== undefined ? `data-value=${data}` : ""
               } class="dropdown-item ${
                 cls ? cls : ""
               }" type="button">${text}</a>`
           )
           .join("")}
      </div >
   </div > `;

export const serialise = (form) => {
  const data = {};
  let text = "";
  form
    .querySelectorAll("input[name], textarea[name], button[name], select[name]")
    .forEach((el) => {
      data[el.name] = el.value;
      text += el.value + "\n";
    });
  return { serialised: JSON.stringify(data), text };
};

export const showError = (el, reason) => {
  const parentEl = el && el.parentElement;

  if (el?.type === "checkbox") {
    parentEl?.classList.add("error");
    return;
  }

  if (el && el.tagName === "SELECT") {
    const labelSelect = parentEl.querySelector("label:not(.reason)");
    if (labelSelect) {
      labelSelect.classList.add("d-none");
    }
  }

  if (el && !parentEl.querySelector(".reason")) {
    const reasonLabel = document.createElement("label");
    reasonLabel.innerHTML = reason || "Required";
    reasonLabel.classList.add("reason", "shrink");
    el.after(reasonLabel);
  }

  if (el.value) {
    parentEl.querySelector("label:not(.reason)")?.classList.add("d-none");
  }

  parentEl.classList.add("error");
};

export const elVisible = (elem) => {
  return !!(
    elem.offsetWidth ||
    elem.offsetHeight ||
    elem.getClientRects().length
  );
};

export const validateInput = (el) => {
  if (
    el.dataset.required === "true" &&
    ((el.type !== "checkbox" && !el.value?.trim()) ||
      (el.type === "checkbox" && !el.checked))
  ) {
    showError(el, el.dataset.requiredReason);

    return false;
  }

  if (el.dataset.minLength) {
    if (el.value.length && el.value.length < parseInt(el.dataset.minLength)) {
      let minLengthReason = `Please enter a min of ${el.dataset.minLength} character(s)`;
      if (el.dataset?.minLengthReason) {
        minLengthReason = el.dataset.minLengthReason;
      }

      showError(el, minLengthReason);

      return false;
    }
  }

  if (el.dataset.maxLength) {
    if (el.value.length > parseInt(el.dataset.maxLength)) {
      let maxLengthReason = `Please enter a max of ${el.dataset.maxLength} character(s)`;
      if (el.dataset?.maxLengthReason) {
        maxLengthReason = el.dataset.maxLengthReason;
      }

      showError(el, maxLengthReason);

      return false;
    }
  }

  if (el.dataset.matchInput) {
    const closestForm = el.closest("form");
    const matchInput = closestForm.querySelector(
      `[name=${el.dataset.matchInput}]`
    );
    const inputLabel = matchInput.getAttribute("aria-label");

    if (matchInput.value != el.value) {
      showError(el, `Please make sure to match '${inputLabel}' field.`);

      return false;
    }
  }

  if (el.dataset.fileType) {
    for (const file of el.files) {
      if (file.type !== el.dataset.fileType) {
        showError(el, el.dataset.fileTypeReason);

        return false;
      }
    }
  }

  //Like file type, but a comma seporated list.
  if (el.dataset.fileTypes) {
    for (const file of el.files) {
      const types = el.dataset.fileTypes.split(",").map((type) => type.trim());
      if (!types.includes(file.type)) {
        showError(
          el,
          `Only ${types
            .map((type) => type.replace(/(application|image)\//, ""))
            .join(", ")} files are allowed`
        );
        //
        return false;
      }
    }
  }

  if (el.dataset.fileMaxSize) {
    for (const file of el.files) {
      if (file.size * 1 > el.dataset.fileMaxSize * 1) {
        showError(el, el.dataset.fileMaxSizeReason);

        return false;
      }
    }
  }

  if (el.dataset.dateAfterCurrent) {
    // Is not after current date
    if (!dateRangePicker.isDateAfter(el)) {
      showError(el, "Cannot be before or same as the current date");

      return false;
    }
  }

  if (el.dataset.dateAfterYear) {
    const closestForm = el.closest("form");
    const comparisonDate = closestForm.querySelector(
      `[name=${el.dataset.dateBefore}]`
    );

    // Is one year after the dateBefore input
    if (
      dateRangePicker.isDateAfter(
        el,
        addYears(dateRangePicker.getDate(comparisonDate).toDate(), 1)
      )
    ) {
      showError(el, "Cannot be after one year duration");

      return false;
    }
  }

  if (el.dataset.dateBefore) {
    const closestForm = el.closest("form");
    const matchInput = closestForm.querySelector(
      `[name=${el.dataset.dateBefore}]`
    );
    const inputLabel = matchInput.getAttribute("aria-label");

    // Is before the dateBefore input
    if (
      dateRangePicker.isDateAfter(
        matchInput,
        dateRangePicker.getDate(el).toDate()
      )
    ) {
      showError(el, `Cannot be before the ${inputLabel}`);

      return false;
    }
  }

  if (el.dataset.maxNumber) {
    const maxNumber = el.dataset.maxNumber;
    const maxNumberType = el.dataset.maxNumberType;
    const value = el.value;
    if (parseInt(value) > parseInt(maxNumber)) {
      if (maxNumberType === "percentage") {
        showError(el, `Value cannot be greater than ${maxNumber}%.`);
      } else {
        toast.displayToast({
          type: "error",
          titleText: "Error",
          bodyMessageText:
            "Please note, the maximum discount for an item is 100%."
        });
      }

      return false;
    }
  }

  if (el.dataset.minNumber) {
    const minNumber = el.dataset.minNumber;
    if (helpers.localStringToNumber(el.value) < parseInt(minNumber)) {
      showError(el, `Value cannot be less than ${minNumber}`);

      return false;
    }
  }

  if (el.dataset.pattern) {
    const passed = new RegExp(el.dataset.pattern).test(el.value);
    if (!passed) showError(el, el.dataset.patternReason);
    return passed;
  }

  return true;
};

export const validate = (form, ignore) => {
  let passed = true;
  form
    .querySelectorAll("input[name], textarea[name], button[name], select[name]")
    .forEach((el) => {
      if (ignore && ignore.length > 0) {
        if (!ignore.includes(el.name)) {
          const validated = validateInput(el);
          if (passed) passed = validated;
        }
      } else {
        const validated = validateInput(el);
        if (passed) passed = validated;
      }
    });

  return passed;
};

export const clearElement = (el) => {
  if (!el || el.classList.contains("js-no-clear")) return;
  insertValue(el);
};

export const clearForm = ({ parent, names = [] }) => {
  if (parent && names.length) {
    for (const name of names) {
      const el = parent.querySelector(`[name="${name}"]`);
      clearElement(el);
    }
  } else if (parent) {
    parent
      .querySelectorAll("input, select, textarea, .alt-input")
      .forEach((el) => {
        clearElement(el);
      });
  }
};

export const clearModalForm = (modal) => {
  modal.querySelectorAll("input, select").forEach((el) => {
    insertValue(el);
    if (el.tagName.toLowerCase() === "select") {
      tailSelect(el).reload();
    }
  });
};

export const shrinkLabels = (target) => {
  if (!target) {
    target = document;
  }

  const allInputBase = target.querySelectorAll(
    ".input-base input, .input-base textarea,  .input-base button, .input-base select"
  );

  for (const inputBase of allInputBase) {
    if (inputBase.value && inputBase.nextElementSibling) {
      inputBase.nextElementSibling.classList.add("shrink");
    }
  }
};

export const fillInFields = ({ name, obj, prop = name, parent = document }) => {
  const findFields = (el) => {
    el.querySelectorAll(`[name=${name}],[data-name=${name}`)?.forEach(
      (elFields) => {
        insertValue(elFields, obj[prop]);
      }
    );
  };
  if (!(name && obj)) return;
  if (parent.length) parent.forEach((el) => findFields(el));
  else findFields(parent);
};

export const insertValue = (el, val, obj, paths) => {
  if (paths) {
    paths = paths.split(".");

    if (paths && paths.length > 0) {
      for (const path of paths) obj = obj?.[path] || null;
    }

    val = obj?.[el.name] || null;
  }
  switch (el.tagName) {
    case "INPUT": {
      if (el.type === "file") return;
      if (el.type === "checkbox") {
        el.checked = !!val;
      } else {
        if (val !== null && val !== undefined) {
          if (el.type === "date" || el.dataset.type == "date") {
            el.value = datetimepicker.formatDate(val, true);
            datepicker.updateRendereredCalendar(el);
          } else if (el.type === "time" || el.dataset.type == "time") {
            el.value = datetimepicker.formatTime(val);
          } else if (el.type === "datetime" || el.dataset.type == "datetime") {
            el.value = datetimepicker.formatDate(val, false);
            datepicker.updateRendereredCalendar(el);
          } else if (el.type === "currency" || el.dataset.type == "currency") {
            return;
          } else {
            el.value = helpers.stripHTML(val);
          }
          el.classList.remove("d-none");
          el.closest(".input-base:not(.js-tail-select)")
            ?.querySelector("label")
            ?.classList.add("shrink");
        } else if (!val) {
          el.value = "";
          const date = new Date();
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          jQuery(el).data("daterangepicker")?.setStartDate(date);
          jQuery(el).data("daterangepicker")?.setEndDate(date);
          if (!el.placeholder)
            el.closest(".input-base:not(.js-tail-select)")
              ?.querySelector("label:not(.no-shrink):not(.reason)")
              ?.classList.remove("shrink");
        }
      }
      removeReason(el, { empty: true });
      el.closest(".input-base")?.classList.remove("error");
      break;
    }
    case "TEXTAREA": {
      if (val) {
        el.value = val;
        el.classList.remove("d-none");
        el.closest(".input-base")
          ?.querySelector("label")
          ?.classList.add("shrink");
      } else if (!val) {
        el.value = "";
        el.closest(".input-base")
          ?.querySelector("label")
          ?.classList.remove("shrink");
      }
      break;
    }
    case "SELECT": {
      const changed = el.value != val;
      el.value = val;
      const ib = el.closest(".input-base");
      if (isSelectComponent(el)) {
        if (isUndefined(val) || isNull(val)) {
          unselectSelectedOptions(el);
        } else {
          setSelectSelectedOption(el, val);
        }
      } else if (ib.classList.contains("js-tail-select")) {
        tailSelect(el).reload();
        if (
          (val === undefined || val === null) &&
          el.querySelector('option[value="0"]')
        ) {
          tailSelect(el).reload();
        } else if (val === undefined || val === null) {
          jQuery(el).val(val).trigger("change");
          tailSelect(el).reload();
        } else {
          tailSelect(el).options.select(val, "#");
          tailSelect(el).reload();
        }

        dispatchManualEvent(el, "change");
      } else {
        const a = ib?.querySelector(".dropdown-toggle a");
        const opt =
          val === undefined || val === null
            ? el.querySelector("option:first-child")
            : el.querySelector(`[value="${val.toString()}"]`);
        ib.querySelector("label")?.classList.add("shrink");
        if (a && opt) a.innerHTML = opt.innerHTML;

        if (changed) {
          dispatchManualEvent(el, "change");
        }
      }
      removeReason(el, { empty: true });
      el.closest(".input-base")?.classList.remove("error");
      break;
    }
    default: {
      el.innerHTML = val ?? "";
      el.classList.remove("d-none");
    }
  }
};

export const createDropDown = ({
  select,
  relative,
  bullet,
  dropdownPos,
  extend
}) => {
  const dropdown = document.createElement("div");
  dropdown.classList.add("dropdown");

  let html = `
         <div class='tail'><i class="fas fa-angle-down"></i></div>
	      <button
	         class="btn dropdown-toggle"
	         type = "button"
	         data-toggle="dropdown"
	         aria-haspopup="true"
	         aria-expanded="false"
	      >
	         <a class="dropdown-item" type="button">
	            ${select.querySelectorAll("option")[0]?.innerHTML}
	         </a>
	      </button>
	      <div
	         class="dropdown-menu ${dropdownPos} ${
    extend ? "dropdown-extend" : ""
  } ${relative ? " grid__dropdown-menu js-dropdown-menu" : ""}${
    bullet ? "" : " no-bullet"
  }"
	         aria-labelledby="dropdownMenuButton"
	      >`;
  const currentStatusValue = Number(
    select.querySelectorAll("option")[0]?.value
  );
  if (
    [jobStatusTypeId.INVOICED, jobStatusTypeId.INVOICE_IN_PROGRESS].includes(
      currentStatusValue
    )
  ) {
    html += "";
  } else {
    select.querySelectorAll("option").forEach((opt) => {
      html += `<a data-value="${opt.value}" class="dropdown-item" type="button">${opt.innerHTML}</a>`;
    });
  }

  html += "</div>";

  dropdown.innerHTML = html;
  return dropdown;
};

function formatIfDate(data) {
  if (data && moment(data, moment.ISO_8601, true).isValid()) {
    return formatDateTime(data);
  } else {
    return data;
  }
}

export const buildDropdown = (
  data,
  {
    parent,
    name,
    text,
    val,
    desc,
    obj = [],
    relative,
    dropdownPos,
    click,
    changeCallback,
    descFallback,
    getAttrs = (_opt) => "",
    extend = false
  }
) => {
  let opts = "";
  if (!data) return console.warn("data missing");
  const parentElement =
    typeof parent === "string" ? document.querySelector(parent) : parent;
  const parentContainer = parentElement ? parentElement : document;
  if (!data) return console.warn("data missing. skip buildDropdown");
  for (let type of data) {
    type = obj && obj.length ? type[obj[0]][0] : type;
    const fullDesc =
      desc?.constructor === Array
        ? desc
            .map((d) => {
              if (isNull(type[d])) {
                return descFallback || d;
              }
              if (isUndefined(type[d])) {
                return d;
              }
              return formatIfDate(type[d]);
            })
            .join("")
        : type[desc];

    opts += `<option value="${type[val]}" ${getAttrs(
      type
    )}>${fullDesc}</option>`;
  }

  parentContainer?.querySelectorAll(`select[name="${name}"]`).forEach((el) => {
    el.innerHTML = text ? `<option value="">${text}</option>` : "";
    el.innerHTML += opts;
    const dd = createDropDown({
      select: el,
      relative,
      bullet: undefined,
      dropdownPos,
      extend
    });
    const ib = el.closest(".input-base");

    if (!ib.querySelector(".dropdown")) {
      el.classList.add("d-none");
      el.before(createDropDown({ select: dd }));
    }

    ib.replaceChild(
      selectAble(dd.cloneNode(true), click),
      ib.querySelector(".dropdown")
    );

    if (changeCallback) {
      el.addEventListener("change", changeCallback);
    }

    if (el.dataset.default) {
      ib?.querySelector(
        `.dropdown-item[data-value="${el.dataset.default}"]`
      )?.click();
    }
  });
};

export const agDropDownMenuFix = ({ pageInstance }) => {
  let $dropdownMenu;

  jQuery(".js-grid-table").on("show.bs.dropdown", (e) => {
    // grab the menu
    const $dd = jQuery(e.target);

    $dropdownMenu = $dd.find(".dropdown-menu");

    if (
      !$dropdownMenu.closest(".js-grid-table").length &&
      !$dropdownMenu.hasClass("grid__dropdown-menu")
    )
      return;

    // detach it and append it to the body
    jQuery("body").append($dropdownMenu.detach());

    // grab the new offset position
    const eOffset = $dd.offset();
    // make sure to place it where it would normally go (this could be improved)
    $dropdownMenu.css({
      display: "block",
      "min-width": "25rem",
      top: eOffset.top + jQuery(e.target).outerHeight(),
      left: eOffset.left - 225
    });

    $dropdownMenu.addClass("dropdown-no-limit");

    const dropdownMenuElement = $dropdownMenu[0];

    if (!dropdownMenuElement?.classList.contains("generated")) {
      if (pageInstance) {
        if (typeof pageInstance.actionButtonsHandler === "function") {
          // Set action buttons handler
          pageInstance.actionButtonsHandler(dropdownMenuElement);
        }
      }
    }

    dropdownMenuElement?.classList.add("generated");
  });

  // and when you hide it, reattach the drop down, and hide it normally
  jQuery(".js-grid-table").on("hide.bs.dropdown", (e) => {
    if (
      !jQuery(e.target).closest(".js-grid-table").length &&
      !jQuery(e.target).closest(".datatables__mini-table .js-grid-invoice")
        .length
    )
      return;

    if (!$dropdownMenu) return;
    jQuery(e.target).append($dropdownMenu.detach());
    $dropdownMenu.hide();
  });

  // Resize grid when visible
};

//Selects an option in the dropdown menu
export const selectAble = (dropdown, click) => {
  const clickable = (el) => {
    const defaultHandler = function () {
      const inputBase = el.closest(".input-base");
      const val = this.dataset.value || this.text;
      const select = inputBase.querySelector("select");

      let text = this.text;
      if (this.text.length > 45) {
        text = this.text.slice(0, 44) + "...";
      }
      inputBase.querySelector(".dropdown-toggle a").innerHTML = text;

      if (select) {
        select.value = val;
        dispatchManualEvent(select, "change");
      }
    };

    if (el) {
      // element exists, bind event listener
      el.querySelectorAll(".dropdown-menu a").forEach((opt) => {
        opt.addEventListener("click", click || defaultHandler);
      });
    }

    return el;
  };

  if (dropdown) return clickable(dropdown);
  else $("select").forEach((el) => clickable(el.closest(".input-base")));
};

export const inputLists = () => {
  $(".input-base__option--single").forEach((el) => {
    el.addEventListener("click", function () {
      const inputBase = this.closest(".input-base");
      const cbx = this.closest(".input-base").querySelector("input");
      const name = cbx.dataset.name;
      const form = this.closest("form");
      const checked = form.querySelectorAll('input[type="checkbox"]:checked');
      if (checked) {
        checked.forEach((input) => {
          input.checked = false;
          input.value = "";
          input.dataset.name = name;
          input.removeAttribute("name");
        });
      }
      form
        .querySelectorAll(".highlight")
        ?.forEach((highlightEl) => highlightEl.classList.remove("highlight"));
      inputBase.classList.add("highlight");
      cbx.name = name;
      cbx.value = cbx.id;
      cbx.click();
    });
  });
};

export const createTailSelect = ({ data, val, text, id }) => {
  let dd = `<select id="${id}" class="${id} tail-select filter-tail-select-text">`;
  let options = "";
  for (const option of data) {
    options += `<option value="${option[val]}">${option[text]}</option>`;
  }
  dd = dd + options + "</select>";
  return dd;
};

/**
 * @param {HTMLElement | NodeListOf<HTMLElement> | Array<HTMLElement>} els
 */
export const countryTailSelect = async (els) => {
  try {
    const finalEls =
      Array.isArray(els) || els instanceof NodeList ? els : [els];
    finalEls.forEach((el) => {
      el.classList.add("tail-select", "filter-tail-select-text");
    });
    await CountrySelect.createMany(finalEls);
    return CountrySelect.countries;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const buildSearchableDropdown = (
  el,
  data,
  label,
  value,
  placeholder,
  includePlaceholder
) => {
  // Destroy any existing tailSelect instance to avoid conflicts
  if (el.tailselect) {
    el.tailselect.remove();
    el.tailselect = null;
  }

  // Clear select options
  el.innerHTML = "";

  const options = data.map(
    (v) => `<option value="${resolve(value, v)}">${resolve(label, v)}</option>`
  );
  if (includePlaceholder) {
    options.unshift(`<option value="">${placeholder}</option>`);
  }

  el.classList.add("tail-select");
  el.innerHTML = options.join("");

  const selectInstance = tailSelect(el, {
    placeholder,
    search: true
  });

  el.tailselect = selectInstance;
  return el;
};

const resolve = (path, obj) => {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj || self);
};

export const removeReason = (el, { empty } = {}) => {
  const reason = el.parentElement.querySelector(".reason");
  if (reason) el.parentElement.querySelector(".reason").remove();
  if (!empty)
    el.parentElement
      .querySelector("label:not(.no-shrink)")
      ?.classList.add("shrink");
  el.closest(".input-base")
    ?.querySelector("label:not(.reason)")
    ?.classList.remove("d-none");
};

export const inputAnimations = (element, parent) => {
  const animateLabel = (el) => {
    const input = el.querySelector("input,textarea,select");
    if (!input) return;

    if (
      ["TEXTAREA", "INPUT"].includes(input.tagName) &&
      input.type !== "checkbox"
    ) {
      input.addEventListener("focus", function () {
        removeReason(this);
      });
      input.addEventListener("focusout", function () {
        const inputBase = this.parentElement;
        if (!this.value) {
          if (!this.placeholder)
            inputBase
              .querySelector("label:not(.no-shrink):not(.reason)")
              ?.classList.remove("shrink");
        } else {
          inputBase.classList.remove("error");
          if ($(".highlighted").length && !this.closest(".modal"))
            validate(this.closest("form"));
        }
      });
    } else if (
      ["SELECT"].includes(input.tagName) ||
      input.type === "checkbox"
    ) {
      input.addEventListener("change", function () {
        if (
          el
            .closest(".input-base, .custom-switch")
            .classList.contains("error") &&
          validateInput(this)
        ) {
          const parentEl = this.parentElement;
          parentEl?.classList.remove("error");
          parentEl.querySelector(".reason")?.classList.add("d-none");
          parentEl
            .querySelector("label:not(.reason")
            ?.classList.remove("d-none");

          if ($(".highlighted").length) {
            validate(this.closest("form"));
          }
        } else {
          //unvalidate it here
        }
      });
      input.addEventListener("click", function () {
        removeReason(this);
      });
    }

    el.querySelector("label")?.addEventListener("click", function () {
      this.parentElement.querySelector("input")?.focus();
    });

    el.addEventListener("click", function () {
      el.querySelector("input, select")?.focus();
    });
  };

  if (element) {
    if (parent) {
      element
        .querySelectorAll(".input-base, .custom-switch")
        .forEach((el) => animateLabel(el));
    } else {
      animateLabel(element);
    }
  } else {
    $(".section .input-base, .section .custom-switch").forEach((el) =>
      animateLabel(el)
    );
  }
};

/**
 * Event handler for search
 * @returns {() => void} Cleanup function to remove the listener on the search input
 */
export const searchEventHandler = (searchInput, callback) => {
  if (!searchInput) return () => {};

  const debounceHandler = debounce(1500, callback);
  searchInput.addEventListener("keyup", debounceHandler);

  return () => {
    if (debounceHandler.timerId) {
      window.clearTimeout(debounceHandler.timerId);
    }

    searchInput.removeEventListener("keyup", debounceHandler);
  };
};

export const createNavFilterDropDown = (
  data,
  val,
  text,
  id,
  defaultVal = null
) => {
  let options = "";

  for (const option of data) {
    options += `<option value ="${option[val]}" ${
      option[val] === defaultVal ? "selected" : ""
    }>${option[text]}</option>`;
  }

  return `<select id=${id} multiple class='${id} tail-select filter-tail-select-text'>${options}</select>`;
};

export const formatMultiSelect = (multiselectedValue) => {
  if (multiselectedValue) {
    return multiselectedValue.join();
  } else return null;
};

/**
 * Debounce for search
 */
const debounce = (delay, fn) => {
  let timerId;

  function debounceWrapper(...args) {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  }

  Object.defineProperty(debounceWrapper, "timerId", {
    get() {
      return timerId;
    }
  });

  return debounceWrapper;
};

const forms = () => {
  const autoFocusModals = () => {
    jQuery(".modal:not(.no-auto-focus)").on("show.bs.modal", function () {
      setTimeout(() => {
        jQuery(this).find(".modal-body input[type=text]:first").focus();
      }, 500);
    });
  };

  const createDropDowns = () => {
    $(
      ".input-base:not(.js-tail-select):not(.input-base-select2) select"
    ).forEach((sel) => {
      sel.classList.add("d-none");
      sel.before(createDropDown({ select: sel }));
    });
  };

  const agGridFixes = () => {
    let $dropdownMenu;
    jQuery(window).on("show.bs.dropdown", (e) => {
      e.stopPropagation();
      // grab the menu
      const $dd = jQuery(e.target);
      $dropdownMenu = $dd.find(".dropdown-menu");

      if (!$dropdownMenu.hasClass("js-dropdown-menu")) return;
      // detach it and append it to the body
      jQuery("body").append($dropdownMenu.detach());
      // grab the new offset position
      const eOffset = $dd.offset();

      // make sure to place it where it would normally go (this could be improved)
      $dropdownMenu.css({
        display: "block",
        top: eOffset.top + jQuery(e.target).outerHeight(),
        left: eOffset.left
      });
    });
    // and when you hide it, reattach the drop down, and hide it normally
    jQuery(window).on("hide.bs.dropdown", function (e) {
      if (!$dropdownMenu.hasClass("js-dropdown-menu")) return;
      jQuery(e.target).append($dropdownMenu.detach());
      $dropdownMenu.hide();
    });

    //Resize grid when visible
  };

  inputAnimations();
  autoFocusModals();
  createDropDowns();
  selectAble();
  inputLists();
  agGridFixes();
};

/**
 * Updates the given element with `data-required` attribute if the `data-required-when` attribute is
 * present and the targeted element has a value.
 * Updates the other elements with `data-required` attribute for any descendant element of the parent form
 * that contains a `data-required-when` with the `inputEl.name`.
 *
 * @param {HTMLFormElement} parentForm
 * @param {HTMLInputElement | HTMLSelectElement} inputEl
 * @example
 * ```html
 * <!-- HTML template -->
 * <form>
 *   <input type="text" name="name" data-required-when="last-name">
 *   <input type="text" name="last-name" data-required-when="name">
 *   <input type="number" name="age" data-required-when="name">
 * </form>
 * ```
 *
 * ```js
 * // JS file
 * document.querySelectorAll("input").forEach(el => {
 *   el.addEventListener("change", e => {
 *     updateRequiredWhen(el.closest("form"), el);
 *   })
 * })
 * ```
 *
 * Whenever an input changes, the data-required will be set to true/false based on the data-required-when.
 */
export const updateRequiredWhen = (parentForm, inputEl) => {
  // Update the current's input data-required attribute based on the targeted input name that it needs to meet criteria
  const [name] = getRequiredWhenTuple(inputEl);
  if (name) {
    setRequiredWhen(inputEl, parentForm.querySelector(`[name=${name}]`));
  }

  // Update all elements that would derive their data-required attribute based on this input
  parentForm
    .querySelectorAll(`[data-required-when^=${inputEl.name}]`)
    .forEach((otherEl) => {
      setRequiredWhen(otherEl, inputEl);
    });
};

const setRequiredWhen = (input, other) => {
  const [name, value] = getRequiredWhenTuple(input);
  if (name && other?.name === name) {
    // If the value is the same or has data
    const isRequired = value ? other.value == value : !!other.value;
    input.setAttribute("data-required", isRequired);
  }
};

/**
 * @param {HTMLInputElement | HTMLSelectElement} input
 * @returns {[name?: string, value?: string]}
 */
const getRequiredWhenTuple = (input) => {
  return input.dataset.requiredWhen?.split("=") ?? [];
};

const exports = {
  init: forms,
  serialise,
  createGridDropdown,
  validate,
  validateInput,
  insertValue,
  removeReason,
  clearForm,
  clearModalForm,
  countryTailSelect,
  buildDropdown
};

export default exports;
